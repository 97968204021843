import React from "react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import { Link } from "react-router-dom";

SwiperCore.use([Pagination]);
const IntroContainer = () => {
    const swiperOption = {
        loop: true,
        speed: 750,
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: { clickable: true },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    };
    return (
        <div className="intro-slider-wrap">
            <div
                className="intro-section section overlay intro-slider"
                style={{
                    backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        "/img/hero-slider/Joana_Beste.jpg"
                    })`,
                }}
            >
                <div className="container">
                    <div className="row row-cols-lg-1 row-cols-1">
                        <div className="col align-self-center">
                            <div className="intro-content">
                                <span className="sub-title">Joana Beste</span>
                                <h2 className="title">Fotografie</h2>
                                <Link
                                    to={process.env.PUBLIC_URL + "/portfolio"}
                                    className="intro-btn"
                                >
                                    Galerie entdecken
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroContainer;
