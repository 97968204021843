import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";

const legalNotice = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title=" Joana Beste | Impressum" />
                <div className="wrapper home-default-wrapper">
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        <div className="container">
                            <div className="row">
                                <p>
                                    <h2>Impressum</h2>
                                    <p>
                                        Joana Beste <br />
                                        Zur Siedlung 2<br /> 32339 Espelkamp
                                    </p>
                                    <h2>Kontakt</h2>
                                    <p>info@joana-beste.de</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default legalNotice;
