import PageTitle from "../../../components/page-title";

const PageTitleContainer = () => {
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <PageTitle
                            classOption="page-title-content content-style2 text-center"
                            subTitle="Über mich"
                            title="Hey, ich bin Joana"
                        />
                    </div>
                </div>
            </div>
            <div className="thumb" data-aos="fade-up" data-aos-delay="300">
                <div className="intro-section ">
                    <img
                        className="w-60 container"
                        src={
                            process.env.PUBLIC_URL +
                            "./img/about/Joana_Beste.jpg"
                        }
                        alt="Joana Beste"
                    />
                </div>
            </div>
        </div>
    );
};

export default PageTitleContainer;
