import AboutData from "../../../data/global/about.json";

const Blockquote = () => {
    return (
        <div className="blockquote-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <blockquote className="blockquote-style">
                            <div className="font-size-24">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: AboutData[3].excerpt,
                                    }}
                                />
                            </div>
                            <div className="author-info">
                                <span className="name">Joana Beste</span>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blockquote;
