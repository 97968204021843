import { NavLink, Route } from "react-router-dom";
import FooterLogo from "../../components/footer-logo";
import PrivacyPage from "../../pages/privacy";
import Contact from "../../pages/contact";

const Footer = () => {
    return (
        <footer className="footer-area reveal-footer border-top-style">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="footer-content">
                            <div className="widget-item text-center centered">
                                <div className="about-widget ">
                                    <FooterLogo
                                        image={`${process.env.PUBLIC_URL}/img/logo.png`}
                                    />
                                </div>
                                <div className="widget-copyright">
                                    <p>
                                        © 2021 <span>Beste</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="widget-item">
                            <div className="widget-footer-nav">
                                <nav>
                                    <ul>
                                        <li>
                                            <NavLink
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/privacy"
                                                }
                                            >
                                                Datenschutz
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/impressum"
                                                }
                                            >
                                                Impressum
                                            </NavLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
